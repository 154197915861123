//
//
//
//
//
//
//
//
//
//
//
//

import Header from "./Header.vue";
import SellingPoints from "./SellingPoints.vue";
import Services from "./Services.vue";
import Differentiators from "./Differentiators.vue";
import Message from "./Message.vue";
import Testimonials from "./Testimonials.vue";
import Packages from "./Packages.vue";

export default {
  name: "Home",

  components: {
    Header,
    SellingPoints,
    Services,
    Differentiators,
    Message,
    Testimonials,
    Packages
  }
};
