//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import sectionTitleComponent from './sectionTitleComponent';
import packageComponent from './packageComponent';
import { packageItemArr } from '../constants/packages.const';

export default {
    name: "Packages",

    components: {
        sectionTitleComponent,
        packageComponent
    },

    data() {
        return {
            packageItems: packageItemArr,
            packagesTitleHeading: this.$t('packagesTitleHeading'),
        }
    }
};
