//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import iconComponent from './iconComponent';
import { sellingPointsArr } from '../constants/icons.const';

export default {
    name: 'selling-pointserentiators',

    components: {
        iconComponent
    },

    data() {
        return {
            sellingPoints: sellingPointsArr
        }
    }
}
