//
//
//
//
//
//
//
//
//
//
//

import sectionTitleComponent from './sectionTitleComponent';
import carouselComponent from './carouselComponent';
import { testimonialsArr } from '../constants/testimonials.const';

export default {
    name: 'Testimonials',

    components: {
        sectionTitleComponent,
        carouselComponent
    },

    data() {
        return {
            testimonialsTitleHeading: this.$t('testimonialsTitleHeading'),
            testimonials: testimonialsArr
        }
    }
}
