//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "Header",

  methods: {
    goToPackagesPage() {
      this.$router.push("/packages");
    }
  }
};
