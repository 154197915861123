//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import sectionTitleComponent from './sectionTitleComponent';
import iconComponent from './iconComponent';
import { differentiatorsArr } from '../constants/icons.const';

export default {
    name: 'Differentiators',

    components: {
        sectionTitleComponent,
        iconComponent
    },

    data() {
        return {
            diffTitleHeading: this.$t('diffTitleHeading'),
            differentiators: differentiatorsArr
        }
    }
}
