//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'carouselComponent',

    data () {
        return {
            slide: 'carouselItem1',
            autoplay: true
        }
    },

    props: {
        carousel: Array
    }
}
