//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import serviceComponent from "./serviceComponent";
import { servicesArr } from '../constants/services.const';

export default {
    name: "Services",
    
    data() {
        return {
           services: servicesArr,
        };
    },

    components: {
        serviceComponent,
    },
};
