//
//
//
//
//
//
//
//
//

export default {
    name: 'Message'
}
