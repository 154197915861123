export const sellingPointsArr = [
    { title: 'sellingPointTitle1', text: 'sellingPointText1', icon: require("../assets/img/conversionIcon.svg"), alternative: "conversion" },
    { title: 'sellingPointTitle2', text: 'sellingPointText2', icon: require("../assets/img/voiceIcon.svg"), alternative: "voice" },
    { title: 'sellingPointTitle3', text: 'sellingPointText3', icon: require("../assets/img/partnerIcon.svg"), alternative: "collaboration" }
]

export const differentiatorsArr = [
    { title: 'diffTitle1', text: 'diffText1', icon: require("../assets/img/digitalNudgingIcon.svg"), alternative: "digitalNudging" },
    { title: 'diffTitle2', text: 'diffText2', icon: require("../assets/img/intuitiveDesignIcon.svg"), alternative: "intuitiveDesign" },
    { title: 'diffTitle3', text: 'diffText3', icon: require("../assets/img/auditIcon.svg"), alternative: "audit" },
    { title: 'diffTitle4', text: 'diffText4', icon: require("../assets/img/pixelPerfectIcon.svg"), alternative: "pixelPerfect" },
    { title: 'diffTitle5', text: 'diffText5', icon: require("../assets/img/resultsIcon.svg"), alternative: "realResults" },
    { title: 'diffTitle6', text: 'diffText6', icon: require("../assets/img/saleFocusedIcon.svg"), alternative: "saleFocused" },
    { title: 'processTitle1', text: 'processText1', icon: require("../assets/img/resultOrientedIcon.svg"), alternative: "resultsOriented" },
    { title: 'processTitle2', text: 'processText2', icon: require("../assets/img/alwaysThereIcon.svg"), alternative: "alwaysThere" },
    { title: 'processTitle3', text: 'processText3', icon: require("../assets/img/trendsIcon.svg"), alternative: "trendy" },
]